////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  PROJECT MIXINS
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  TITLES
//
////////////////////////////////////////////////////////////////

@mixin title_s1 {
    font-size: clamp( 50px, pxvw( 100px ), 120px );
}

////////////////////////////////////////////////////////////////
//
//  TEXT
//
////////////////////////////////////////////////////////////////

@mixin font_size_global {
    font-size: clamp( 16px, pxvw( 20px ), 24px );
}

@mixin font_size_small {
    font-size: clamp( 14px, pxvw( 16px ), 18px );
}

@mixin font_size_s1 {
    font-size: clamp( 24px, pxvw( 28px ), 32px );
}

////////////////////////////////////////////////////////////////
//
//  LINKS
//
////////////////////////////////////////////////////////////////

@mixin global_link {
    color: $g__c_dark;
    text-decoration: none;
}

@mixin link_animation {
    border-bottom: 1px solid rgba( $g__c_dark, 0.4 );
    transition: border .2s ease-in-out;

    &:hover {
        border-bottom: 1px solid rgba( $g__c_dark, 0.8 );
    }
}

////////////////////////////////////////////////////////////////
//
//  ANIMATION
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  BUTTONS
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  SPACING
//
////////////////////////////////////////////////////////////////

@mixin block_spacing {
    margin-top: $g__m_spacing_m;
    @media screen and (min-width: $g__resp_lg) {
        margin-top: $g__m_spacing_l;
    }
}

////////////////////////////////////////////////////////////////
//
//  FORMS
//
////////////////////////////////////////////////////////////////

@mixin field_global( $border: 'none', $border_color: $g__c_dark ) {
    @include font_size_global;
    font-family: $g__global_fontfamily;
    height: 3rem;
    padding: 0.5rem $g__m_side;
    border-radius: $g__border_radius;
    background: white;
    color: $g__c_dark;
    @if $border == 'border' {
        border: 1px solid $border_color;
    }

    &::placeholder {
        color: rgba( $g__c_dark, 0.5 );
    }

    &:focus {
        border: 1px solid $g__c_dark;
    }
}

@mixin select {
    position: relative;
    display: block;
    appearance: none;
    // background-image: url( '../assets/arrow-select.svg' );
    background-position: right 0.5em center;
    background-repeat: no-repeat;
}

@mixin checkbox {
    $radius: 1rem;
    $border: $g__border_width;

    input[ type=checkbox ],
    input[ type=radio ] {
        display: none;
    }
    
    input[ type=checkbox ]+label,
    input[ type=radio ]+label {
        position: relative;
        padding-left: 2em;
        cursor: pointer;

        &:before, &:after {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            top: 0.1rem;
            width: $radius;
            height: $radius;
            border-radius: $radius;
            border: $border solid $g__c_dark;
            transition: opacity .2s ease-in-out;
        }

        &:after {
            background: $g__c_dark;
            opacity: 0;
        }
    }

    input[ type=checkbox ]:checked+label,
    input[ type=radio ]:checked+label {
        &:after {
            opacity: 1;
        }
    }

    input[ type=checkbox ]:disabled+label,
    input[ type=radio ]:disabled+label {
        opacity: .5;
        cursor: default;

        &:before {
            opacity: .5;
        }

        &:after {
            display: none;
        }
    }
}

@mixin valid_form {
    @include font_size_global;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 2em;
    border-radius: 100px;
    background: $g__c_dark;
    color: white;
    font-weight: normal;
    cursor: pointer;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;

    &:hover {
        color: $g__c_dark;
        background: $g__c_secondary;
    }
}