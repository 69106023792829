////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  HOME 
//
////////////////////////////////////////////////////////////////

#graphic_splah_container {
    height: 100vh;
    overflow: hidden;
    background: white;

    #graphic_splash {
        height: 100vh;
        perspective: 400px;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding-bottom: 2rem;
        
        @media screen and (min-width: $g__resp_lg) {
            align-items: center;
        }

        .g__container {
            width: 100%;
        }

        .lequatre {
            
            // @media screen and (min-width: $g__resp_lg) {
            //     grid-column: span 2;
            // }
            
            .icon_4 {
                width: 100%;
                height: 5vh;
                background: url( '../img/icon-quatre.svg' ) left no-repeat;
                background-size: contain;
                margin-bottom: 1rem;

                @media screen and (min-width: $g__resp_lg) {
                    height: 10vh;
                }
            }

            .link {
                @include global_link;
                @include link_animation;
            }
        }

        .header_slide {
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            .item {
                width: 100vw;
                height: 100%;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

        }
    
    }
}

.content_header {
    @media screen and (min-width: $g__resp_lg) {
        margin-top: -5vh;
        height: 5vh;
    }

    .item {
        @media screen and (min-width: $g__resp_lg) {
            grid-column: span 1;

            &.about {
                grid-column: 4;
            }
        }

        .button {
            display: inline-flex;
            cursor: pointer;

            i {
                display: block;
                margin-left: 1em;
                width: 1em;
                height: 1.2em;
                background: url( '../img/arrow-down.svg' ) center no-repeat;
                transition: transform .2s ease-in-out;
            }

            &:hover i {
                @media screen and (min-width: $g__resp_lg) {
                    transform: translateY( 0.5em );
                }
            }
        }
    }
}

.content_about {
    @include block_spacing;

    .content {
        grid-column: 4 / span 3;
        @include font_size_s1;
    
        a {
            @include link_animation;
        }
    }
}


.large_title {
    @include title_s1;
    font-weight: normal;
    margin-bottom: $g__m_spacing_s;
    display: inline-flex;
    overflow: hidden;

    .word_block {
        display: flex;
        flex-shrink: 0;
        min-width: 25%;
        padding-right: 1em;
    }
}

.slide_container {
    @include block_spacing;
    &.first {
        margin-top: $g__m_spacing_m;
    }

    .large_title {
        margin-bottom: 0;
    }

    .slide {
        display: flex;
        width: 100vw;

        @media screen and (max-width: $g__resp_lg) {
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            @include hide_scroll;
            @include smooth_scroll_mobile;
        }
    }

    .slide_scroll {
        display: flex;
        flex-shrink: 0;
        background: white;

        .item {

            &:not( :last-child ) {
                margin-right: $g__gutter;
            }

            &:first-child .info {
                padding-left: $g__m_side;
            }

            &:last-child .info {
                padding-right: $g__m_side;
            }

            @media screen and (max-width: $g__resp_lg) {
                scroll-snap-align: center;
                max-width: 85vw;
            }

            @media screen and (min-width: $g__resp_lg) {
                opacity: 0.1;
                transition: opacity .2s ease-in-out;
                mix-blend-mode: luminosity;
            }

            &.active, &.active:hover {
                @media screen and (min-width: $g__resp_lg) {
                    opacity: 1;
                    cursor: default;
                    mix-blend-mode: normal;    
                }
            }

            &:hover {
                @media screen and (min-width: $g__resp_lg) {
                    opacity: 0.2;
                    cursor: pointer;
                }
            }

            .img_container {
                @media screen and (min-width:$g__resp_lg) {
                    height: 60vh;
                }
                
                img {
                    display: block;
                    max-width: 100%;
                    // height: calc( 100vw / ( (1244 / 830) + (554 / 829) ) );
    
                    @media screen and (min-width: $g__resp_lg) {
                        height: 100%;
                        width: auto;
                    }
                }
            }


            .info {
                margin-top: 0.5rem;

                .title {
                    @include font_size_global;
                    font-weight: bold;
                }

                .line {
                    margin-top: 0.5rem;
                    display: grid;
                    grid-template-columns: repeat( 3, 1fr );

                    .details {
                        grid-column: span 2;
                        display: grid;
                        grid-template-columns: repeat( 2, 1fr );
                    }
                    
                    .dimensions, .price {
                        display: flex;
                        align-items: center;

                        .at_price {
                            display: inline-flex;
                            text-decoration: line-through;
                            margin-right: 0.5rem;
                        }
                    }
    
                    .button_container {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
    
                        .button {
                            display: inline-flex;
                            justify-content: center;
                            padding: 0.2em 1em;
                            border-radius: 200px;
                            cursor: pointer;
                            background: $g__c_secondary;
                            transition: background-color .2s ease-in-out, color .2s ease-in-out;

                            &:hover {
                                background: $g__c_dark;
                                color: white;
                            }
                        }
                    }
                }

                .reprography_container {

                    .drawer_button_container {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 0.5rem;

                        .drawer_button {
                            display: inline-flex;
                            justify-content: center;
                            border-radius: 200px;
                            padding: 0.2em 1em;
                            background: rgba( $g__c_dark, 0.1 );
                            cursor: pointer;
                            transition: background-color .2s ease-in-out;
        
                            i {
                                display: none;
                                margin-left: 1em;
                                width: 1em;
                                height: 1.2em;
                                background: url( '../img/arrow-down.svg' ) center 0.35em no-repeat;
                                transition: transform .2s ease-in-out;
                            }
                
                            &:hover,
                            &.active:hover {
                                background: rgba( $g__c_dark, 0.2 );
                            }

                            &.active {
                                background: rgba( $g__c_dark, 0.05 );
                            }
    
                            &.active i {
                                display: block;
                                transform: rotate( 180deg ) translateY( -0.2em );
                            }
                        }
                    }


                    .drawer {
                        overflow: hidden;
                        height: 0;
                    }
                }


            }
        }
    }
}

.contact_container {
    @include block_spacing;
    margin-bottom: $g__m_spacing_m;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            margin-bottom: 1rem;
            @include font_size_s1;
        }

        a {
            @include global_link;
            @include link_animation;
        }
    }
}