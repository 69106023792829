////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  RESET
//
////////////////////////////////////////////////////////////////

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	box-sizing: border-box;
}