////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  FOOTER 
//
////////////////////////////////////////////////////////////////

#footer {
    padding-bottom: $g__m_bottom;
    opacity: 0.5;
}