////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  CLASS UTILS
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  NO SCROLL
//
////////////////////////////////////////////////////////////////

.no_scroll {
	overflow: hidden !important;
}
