////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  HEADER 
//
////////////////////////////////////////////////////////////////

#header {
    position: fixed;
    z-index: $g__z_header;
    top: $g__m_top;
    left: 0;
    width: 100vw;

    a {
        @include link_animation;
    }

    .col.logo {
        @media screen and (max-width: $g__resp_lg) {
            margin-bottom: 0.5rem;
        }
    }

    .col {
        @media screen and (max-width: $g__resp_lg) {
            margin-bottom: 0.5rem;
        }
    }

    .address, .phone, .email {
        @media screen and (max-width: $g__resp_lg) {
            display: none;
        }
    }

    .email {
        @media screen and (min-width: $g__resp_lg) {
            grid-column: span 2;
        }
    }

    .instagram {
        @media screen and (min-width: $g__resp_lg) {
            text-align: right;
        }
    }
}