////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  MAIN
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  HTML TAGS
//
////////////////////////////////////////////////////////////////

body {
	background: white;
	color: $g__c_dark;
	font-family: $g__global_fontfamily;
	@include font_size_global;
	@include clean_fonts;
	@include hide_scroll;
	// overflow: hidden; // FIX BUG IMEDIATE SCROLL ON LOAD
}

a {
	@include global_link;
}

////////////////////////////////////////////////////////////////
//
//  SELECTION
//
////////////////////////////////////////////////////////////////

@include selection( $g__c_secondary, $g__c_dark );

////////////////////////////////////////////////////////////////
//
//  MAIN #MAIN
//
////////////////////////////////////////////////////////////////

#main {
	padding-bottom: $g__m_bottom;
}

#tarteaucitron-root {
	position: fixed !important;
	z-index: 1000 !important;
	border: 1px solid red;
	background: white;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;

	* {
		color: black !important;
	}
}