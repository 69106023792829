////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  COOKIE
//
////////////////////////////////////////////////////////////////

#cookie_notice {
    display: none; // init
    @include font_size_small;
    position: fixed;
    z-index: 2000;
    bottom: 0;
    right: 0;
    background: white;
    width: 100vw;
    box-shadow: 0 10px 20px rgba( $g__c_dark, 0.2 );

    @media screen and (max-width: $g__resp_lg) {
        padding: calc( $g__gutter * 0.5 ) $g__gutter;
        align-items: center;
    }

    @media screen and (min-width: $g__resp_lg) {
        padding: calc( $g__gutter * 0.5 );
        flex-direction: column;
        width: 33.3333vw;
    }

    .button_container {
        @media screen and (min-width: $g__resp_lg) {
            display: flex;
            justify-content: flex-end;
        }
    }

    .info {
        @media screen and (max-width: $g__resp_lg) {
            padding-right: $g__gutter;
        }
    }

    .button {
        display: inline-flex;
        border-radius: 100px;
        padding: 0.2em 1em;
        background: $g__c_dark;
        color: white;
        cursor: pointer;
        transition: background-color .2s ease-in-out, color .2s ease-in-out;

        &:hover {
            .no_touch & {
                background: $g__c_secondary;
                color: $g__c_dark;;
            }
        }
    }
}