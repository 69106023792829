////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  ORDER PANEL 
//
////////////////////////////////////////////////////////////////

#order_panel {
    position: fixed;
    z-index: $g__z_order_panel;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    display: none; // init
    
    .overlay {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        background: rgba( $g__c_dark, 0.5 );
    }

    .content_container {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        height: 100%;
        width: 90vw;
        background: white;
        overflow-y: scroll;
        padding-bottom: $g__m_bottom;
        @include hide_scroll;
        @include smooth_scroll_mobile;

        @media screen and (min-width: $g__resp_lg) {
            width: 50vw;
        }
    }

    .buy_container,
    .order_container {
        display: none; // init

        .details {
            display: grid;
            gap: $g__gutter;
            grid-template-columns: repeat( 3, 1fr );
            overflow: hidden;
            border-radius: $g__border_radius;
            background: $g__c_dark;
            color: white;

            .detail_col {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .title {
                    font-weight: bold;
                }
            }

            .specs {
                grid-column: span 2;
                padding: 0.5rem 0;
                padding-right: $g__gutter;
            }

            .img img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .at_price {
                display: inline-flex;
                text-decoration: line-through;
                margin-right: 0.5rem;
            }
        }

        .info {
            margin-bottom: 1rem;
            padding: 1rem 0;

            @media screen and (min-width: $g__resp_lg) {
                margin-bottom: 2rem;
                padding: 1rem;
            }
        }
    }

    .close_container {
        display: flex;
        justify-content: flex-end;
        padding: $g__m_top $g__m_side;
        margin-bottom: 2rem;
        cursor: pointer;

        .close {
            @include link_animation;
        }
    }
}