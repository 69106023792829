////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  VARS
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  GLOBAL CONTEXT
//
////////////////////////////////////////////////////////////////

$g__global_context: 1920px;

////////////////////////////////////////////////////////////////
//
//  GLOBAL FONT STYLES
//
////////////////////////////////////////////////////////////////

$g__global_fontfamily: 'Mercury';

////////////////////////////////////////////////////////////////
//
//  Z INDEXES
//
////////////////////////////////////////////////////////////////

$g__z_order_panel: 900;
$g__z_header: 800;
$g__z_main: 100;
$g__z_base: 100;

////////////////////////////////////////////////////////////////
//
//  COLORS
//
////////////////////////////////////////////////////////////////

$g__c_dark: #1A1A1A;
$g__c_secondary: yellow;

////////////////////////////////////////////////////////////////
//
//  SPACING
//
////////////////////////////////////////////////////////////////

$g__m_side: 20px;
$g__gutter: 20px;
$g__m_top: 1rem;
$g__m_bottom: 1rem;
$g__m_spacing_s: 4rem;
$g__m_spacing_m: 8rem;
$g__m_spacing_l: 16rem;

////////////////////////////////////////////////////////////////
//
//  BORDERS
//
////////////////////////////////////////////////////////////////

$g__border_radius: 3px;
$g__border_width: 1px;