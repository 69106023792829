////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  FONTS
//
////////////////////////////////////////////////////////////////

$g__font_dir: "../fonts/";

@mixin add_font($name, $file, $weight: normal, $style: normal) {
	@font-face {
		font-family: $name;
		// src: url($g__font_dir + $file + '.eot');
		src: local('☺'), 
		// url($g__font_dir + $file + '.eot?#iefix') format('embedded-opentype'), 
		url($g__font_dir + $file + '.woff2') format('woff2'), 
		url($g__font_dir + $file + '.woff') format('woff');
		// url($g__font_dir + $file + '.ttf') format('truetype'), 
		// url($g__font_dir + $file + '.svg') format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}

@include add_font('Mercury', 'MercuryRegular-Regular');