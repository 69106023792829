////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  GRID
//
////////////////////////////////////////////////////////////////

.g__container {
    margin-left: $g__m_side;
    margin-right: $g__m_side;
}

.g__row {
    @media screen and (min-width: $g__resp_lg) {
        display: grid;
        grid-template-columns: repeat( 6, 1fr );
    }
}