////////////////////////////////////////////////////////////////
//
//  ©PRAKT
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  FORM 
//
////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//
//  CARD 
//
////////////////////////////////////////////////////////////////

.form__card {
    background: rgba( $g__c_dark, 0.025 );
    border-radius: $g__border_radius;
    padding: $g__gutter;
}

////////////////////////////////////////////////////////////////
//
//  FIELD GROUP & SELECT GROUP
//
////////////////////////////////////////////////////////////////

.form__field_group,
.form__select_group {
    background: white;
    border: 1px solid rgba( $g__c_dark, 0.1 );
    border-radius: $g__border_radius;
    overflow: hidden;

    @media screen and (min-width: $g__resp_lg) {
        display: flex;
        align-items: center;
    }

    &.margin_top {
        margin-top: 0.5em;
    }

    .label {
        display: flex;

        @media screen and (max-width: $g__resp_lg) {
            padding: 0.5em;
        }

        @media screen and (min-width: $g__resp_lg) {
            padding-left: 1em;
            width: 20%;
        }
    }

    .field,
    .textarea {
        @include field_global;
        
        @media screen and (max-width: $g__resp_lg) {
            width: 100%;
        }

        @media screen and (min-width: $g__resp_lg) {
            flex-grow: 1;
        }
    }

    .textarea {
        min-height: 8em;
    }
}

.form__select_group {
    .select {
        @include field_global;
        @include select;
        width: 100%;

        @media screen and (min-width: $g__resp_lg) {
            width: 80%;
        }
    }
}

.form__checkbox {
    @include checkbox;
    display: flex;
    padding: 0.2em 0;

    &.margin_top {
        margin-top: 0.5em;
    }
}

////////////////////////////////////////////////////////////////
//
//  FORM FOOTER
//
////////////////////////////////////////////////////////////////

.form__footer {
    display: flex;

    &.margin_top {
        margin-top: 1em;
    }

    &.justify_right {
        justify-content: flex-end;
    }

    .footer_button:not( :first-child ) {
        margin-left: 0.5em;
    }
}

////////////////////////////////////////////////////////////////
//
//  BUTTONS ( FORM FOOTER ) 
//
////////////////////////////////////////////////////////////////

.form__valid {
    @include valid_form;
}

////////////////////////////////////////////////////////////////
//
//  MESSAGES / ERROR / SUCCESS 
//
////////////////////////////////////////////////////////////////

.form__message {
    display: none; // init
    margin-top: 1rem;
    padding: $g__gutter;
    background: $g__c_secondary;
}